// Here you can add other styles
$biru: #1E88E5;
$hijau: #16A75C;
$tes: #fafafa;
$kuning: #FFD026;
$subtitleColor: #787A91;

.dashboard-custom {
  background-color: $tes;
  height: 100%;
  min-height: 100vh;
}

.header-custom {
  background-color: $kuning;
  padding: 2px;
}

.navbar-custom {
  // box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  padding: 20px;

  .nav-link-custom {
    text-transform: uppercase;
    letter-spacing: 0.3px;
    font-weight: bold;
    margin-left: 50px;
  }
}

.informasi-ppdb {
  background-color: $hijau;
  padding: 30px;

  .title-informasi {
    font-size: 26px;
    font-weight: bold;
    letter-spacing: 1px;
    color: #fff;
    margin-bottom: 0;
  }

  .deskripsi-informasi {
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #fff;
    margin-bottom: 0;
  }
}

.footer-ppdb {
  background-color: #fff;
  padding: 50px 0px 50px 0px
}

.card-custom {
  padding: 0px 10px 0px 10px;
  border: none;
  border-radius: 5px;
  top: 20px;
  box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.05);
}

.primary-text {
  color: $biru;
}

.success-text {
  color: $hijau;
}

.warning-text {
  color: $kuning;
}

.title-jml {
  font-size: 16px;
  font-weight: 500;
  // margin-bt: 0;
}

.title-chart {
  font-weight: bold;
  font-size: 20px;
}

.subtitle-jml {
  color: #616161;
  font-weight: bold;
  font-size: 24px;
  margin: 0;
}

.card-custom.primary {
  border-bottom: 5px solid $biru;
}

.border-icon-custom {
  font-size: 24px;
  border: none;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.05);
}

// .border-bottom {
//   border: 3px solid $hijau;
//   border-bottom-right-radius: 20px;
//   border-top-right-radius: 20px;
//   position: absolute;
//   width: 25%;
//   bottom: 0;
//   z-index: 1;
//   left: 0;
// }

a {
  color: $biru
}

a:hover {
  color: $biru
}

a.link-prev {
  color: #3C4B64;
}

a.link-prev:hover {
  text-decoration: none;
}

.card-ppdb {
  border: none;
  border-radius: 15px;
}

.card-body-ppdb {
  padding: 50px;

  .title {
    font-weight: bold;
    letter-spacing: 0.5px;
  }

  .subtitle {
    letter-spacing: 0.5px;
    font-weight: 500;
    color: $subtitleColor;
  }
}

.modal-content {
  border: none;
  border-radius: 15px;

  .modal-body {
    padding: 30px;

    h2 {
      font-weight: bold;
      letter-spacing: 0.5px;
      margin-bottom: 20px;
    }

    label {
      font-weight: 500;
      letter-spacing: 0.5px;
    }
  }
}

.c-sidebar {
  color: #fff;
  background: #3f4a59;
}

.c-sidebar-nav-link {
  font-weight: 500;
}

.c-sidebar .c-sidebar-nav-link:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
  color: #fff;
  background: #00a4a6;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  line-height: 1.25;
  border: 1px solid;
  border-top-color: currentcolor;
  border-right-color: currentcolor;
  border-bottom-color: currentcolor;
  border-left-color: currentcolor;
  color: $biru;
  background-color: #fff;
  border-color: #d8dbe0;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: $biru;
  border-color: $biru;
}

.page-link:hover {
  z-index: 2;
  text-decoration: none;
  color: $biru;
  background-color: #d8dbe0;
  border-color: #c4c9d0;
}

.border-keterangan {
  border: 3px solid #3f4a59;
  padding: 5px;
  border-bottom-left-radius: 30px;
  border-top-right-radius: 30px;
  // box-shadow: 10px 10px;
}

.react-date-picker {
  display: flex !important;
}

.card-dashboard {
  padding: 20px;
  border: 0;
  border-radius: 5px;
  /* text-transform: uppercase; */
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.stat-content h2 {
  font-weight: 700;
  letter-spacing: 0.5px;
  color: #fff;
  margin: auto;
}

.stat-content h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  color: #fff;
  margin: auto;
}

// h2 {
//   font-weight: 700;
//   letter-spacing: 0.5px;
//   color: #fff;
//   margin: auto;
// }

.grafik h5 {
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #000;
  margin: 0;
}

.card {
  background-color: #fff;
}

.card-judul {
  border: 1px solid #dadada;
  border-radius: 10px;
  transition: 1s ease all;
  background-image: linear-gradient(to bottom,
      #0087d0,
      #008fd9,
      #0198e3,
      #00a0ec,
      #00a9f6);
  /* background-image: linear-gradient(to bottom, #004fb3, #0056bb, #015cc3, #0063cb, #006ad3); */
  background-color: #000;
}

.card-judul:hover {
  cursor: pointer;
  border: 1px solid #321fdb;
  color: #000;
}

.card-sma {
  padding: 20px;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  background-image: linear-gradient(to right,
      #00a9f6,
      #00a0ec,
      #0198e3,
      #008fd9,
      #0087d0);
}

.card-sma .line-sma {
  /* border: 1px solid #ff6384; */
  border-radius: 5px;
  width: 100%;
}

.card-sma:hover {
  cursor: pointer;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.card-sma .total {
  font-size: 1.3rem;
  color: #fff;
  font-weight: 700;
}

.card-sma .ket {
  color: #fff;
  font-weight: 600;
}

.card-sma:hover h1 {
  color: #321fdb;
}

.card-sma:hover h5 {
  color: #7d7c8a;
}

.card-smk {
  padding: 20px;
  border: 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  text-transform: uppercase;
  background-image: linear-gradient(to right,
      #c3598e,
      #ba5086,
      #b0487d,
      #a73f75,
      #9e366d);
}

.card-smk .line-smk {
  border-radius: 5px;
  width: 100%;
}

.card-smk:hover {
  cursor: pointer;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.card-smk .total {
  font-size: 1.3rem;
  color: #fff;
  font-weight: 700;
}

.card-smk .ket {
  color: #fff;
  font-weight: 600;
}

.card-smk:hover h1 {
  color: #321fdb;
}

.card-smk:hover h5 {
  color: #321fdb;
}

.card-slb {
  padding: 20px;
  border: 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  text-transform: uppercase;
  /* background-image: linear-gradient(to right, #00a2aa, #009aa5, #0092a0, #008a9b, #008295); */
  background-image: linear-gradient(to right,
      #00c2b6,
      #00bab4,
      #00b2b1,
      #00aaae,
      #00a2aa);
}

.card-slb .line-slb {
  border-radius: 5px;
  width: 100%;
}

.card-slb:hover {
  cursor: pointer;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.card-slb .total {
  font-size: 1.3rem;
  color: #fff;
  font-weight: 700;
}

.card-slb .ket {
  color: #fff;
  font-weight: 600;
}

.card-slb:hover h1 {
  color: #321fdb;
}

.card-slb:hover h5 {
  color: #321fdb;
}

/* h3{
  color: #fff;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 20px;
} */

.nav-item .nav-link {
  color: #007bff;
  font-size: 15px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #000 !important;
  background-color: #fff;
  border-color: #c4c9d0 #c4c9d0 #fff;
}

.table-jurusan thead th {
  text-transform: uppercase;
  vertical-align: middle;
  text-align: center;
}

.table-jurusan tbody td {
  text-transform: uppercase;
  vertical-align: middle;
  text-align: center;
}

.leaflet-container {
  width: 100%;
  height: 60vh;
}

.leaflet-div-icon {
  background: transparent;
  border: none;
}

.ReactTable .rt-thead .rt-th.-sort-asc {
  box-shadow: none !important;
}

.ReactTable .rt-thead .rt-th.-sort-asc::before {
  content: "\2191";
  position: absolute;
  right: 5px;
}

.ReactTable .rt-thead .rt-th.-sort-desc {
  box-shadow: none !important;
}

.ReactTable .rt-thead .rt-th.-sort-desc::before {
  content: "\2193";
  position: absolute;
  right: 5px;
}

.activity-item {
  padding: 15px;
  border-bottom: 1px solid #f0f0f0;
  margin: 10px;
}

.activity-info {
  color: #000;
}

.activity-info .user-name {
  font-size: 18px;
  font-weight: bold;
}

.activity-info .user-email {
  color: #666;
}

.activity-info .activity-time {
  font-weight: bold;
  color: #666;
  float: right;
}

.activity-title {
  color: #666;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 5px;
}

.activity-body {
  margin-bottom: 10px;
}